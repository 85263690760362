<template>
  <div class="inputBoxWrap inputBox">
    <label>{{item.label}}</label>
      <select
        v-bind:value="value"
        v-on:focus="eventFocus"
        :name="refs"
        :placeholder="item.placeholder"
        v-on="inputListeners"

      >
        <option value="''"></option>
        <option :key="index"
                :value="option.value"
                v-for="(option,index) in inputFilter">
          {{option.name}}
        </option>
      </select>
      <p class="errMsg" v-if="err.length > 0">{{err}}</p>
  </div>
</template>

<script>
  import DataForInput from '@/store/Form/longFormOptions';
  import {mapState} from 'vuex'
  export default {
    name: "fmSelectRef",
    data: function () {
      return {
        item: DataForInput[this.refs],
      }
    },
    props: {
      refs: {
        type: String,
        default: 'null'
      },
      value: {
        type: String,
        default: '',
      },
      err:{
        type: String,
        default: '',
      }
    },
    methods: {
      eventFocus: function(){

      }
    },
    computed: {
      inputFilter: function(){
        let out = [];
        if (this.refVuex.length > 0) {
          let refArray = this.refVuex.filter(key => key.fieldName == this.refs);
          if (refArray.length > 0) {
            out = refArray[0].fieldValues
          }
        }
        return out;
      },
      colorBorder: function () {
        switch (this.status) {
          case 1:
            return 'border-color:#b7b7b7;';
          case 0:
            return 'border-color:green;';
          default:
            return 'border-color:red;';
        }
      },
      errorStatus: function () {
        return false
      },
      ...mapState({
        refVuex: state => state.Form.ref,
      }),
      inputListeners: function () {
        var vm = this;
        // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
        return Object.assign({},
          // Мы добавляем все слушатели из родителя
          this.$listeners,
          // Затем мы можем добавить собственные слушатели или
          // перезаписать поведение некоторых существующих.
          {
            // Это обеспечит, что будет работать v-model на компоненте
            input: function (event) {
              vm.$emit('input', event.target.value)
            }
          }
        )
      }
    },
  }
</script>

<style scoped>
  .inputBoxWrap{position: relative;
    margin: 2vw 0;}
  .inputBoxWrap label{position: absolute;left: 5vw;top: 1vw;color: rgba(51,51,51,.54);-webkit-transition: all .2s ease;transition: all .2s ease;pointer-events: none;font-size: 3vw;}
  .inputBoxWrap select{background: #fff;
    border: 2px solid #dfe0e1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 7px;
    outline: 0;
    padding: 5vw 5vw 2vw;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 5vw;
    line-height: 6.6vw;
    letter-spacing: .1em;
    width: 100%;}
  .inputBoxWrap p{display: block;}

  @media screen and (min-width: 760px) {
    .inputBoxWrap{margin: 1vw 0;}
    .inputBoxWrap label{left: 2.4vw;top: 0;font-size: 1.4vw;}
    .inputBoxWrap select{    padding: 2.4vw 2.4vw .5vw;font-size: 2.4vw;line-height: 3.2vw;}
    .inputBoxWrap p{}
  }
  @media screen and (min-width: 1140px) {
    .inputBoxWrap{margin: 6px 0;}
    .inputBoxWrap label{left: 18px;top: 0;font-size: 10px;}
    .inputBoxWrap select{padding: 18px 18px 7px;font-size: 18px;line-height: 23px;}
    .inputBoxWrap p{}
  }
</style>
